import React from 'react';
import useUser from '@hooks/useUser';
import { SplashScreen, HistoryPosts } from '@components';
import { App } from '@layouts';

const Index = () => {
  const { loading } = useUser();

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <App title="This week">
      <HistoryPosts />
    </App>
  );
};

export default Index;
